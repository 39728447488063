/* eslint-disable no-console */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Router from 'next/router';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/node';
import { GA_ID, SENTRY_DSN } from 'shared/config/config';
import theme from 'styles/theme';
import GlobalStyles from 'styles/globalStyles';
import { AppStateContextProvider } from '../src/store';

if (SENTRY_DSN && process.env.APP_ENV !== 'dev') {
  Sentry.init({
    enabled: true,
    dsn: SENTRY_DSN,
  });
}

const MyApp = ({ Component, pageProps, err }) => {
  if (process.browser) {
    useEffect(() => {
      ReactGA.initialize(GA_ID);
    }, []);

    useEffect(
      () => {
        ReactGA.pageview(Router.asPath);
        localStorage.setItem('language', JSON.stringify('en-US'));
      },
      [Router.asPath]
    );
  }
  // Workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <AppStateContextProvider>
          <Component {...pageProps} err={err} />
        </AppStateContextProvider>
      </>
    </ThemeProvider>
  );
};

MyApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  pageProps: PropTypes.object.isRequired,
  err: PropTypes.object,
};

MyApp.defaultProps = {
  err: {},
};

export default MyApp;
