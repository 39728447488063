/* eslint-disable security/detect-object-injection */

import { css } from 'styled-components';

const breakpoints = {
  desktopSmall: 1260,
  tablet: 900,
  phone: 690,
  phoneSmall: 370,
};

export default Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
